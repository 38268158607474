import React, {useEffect, useState} from "react";
import {Button, Card, Col, Form, Input, message, Modal, Popconfirm, Row, Space, Table} from "antd";
import {GetStatusName} from "../../../../utils/define";
import {
    ajaxAdminOrganizationGetList, ajaxAdminOrganizationGetSyncTelegramInfo,
    ajaxAdminOrganizationSetAdd, ajaxAdminOrganizationSetUserNamePasswordSave
} from "../../../../api";

const formRef = React.createRef()
export default function OrganizationList(props){
    const [listData, setListData] = useState([]);
    const [listPagination, setListPagination] = useState({showSizeChanger: false});
    const [listLoading, setListLoading] = useState(false);
    const [soSO,setSoSO] = useState({});
    const [visible, setVisible] = useState(false);
    const [operate, setOperate] = useState("添加");


    useEffect(() => {
        initFetch()
        return () => {
            // 清理操作，比如取消网络请求或者清除定时器等
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // 空数组意味着仅在组件挂载时执行这个effect


    const initFetch = () => {
        fetch().then()
    }

    const fetch = async (params = {}) => {
        setListLoading(true)
        params.page = params.page ? params.page : 1
        const result = await ajaxAdminOrganizationGetList(params)
        if (result.code === 1) {
            const listPaginationNew = { ...listPagination }
            listPaginationNew.total = result.data.total
            listPaginationNew.pageSize = result.data.limit
            listPaginationNew.showTotal = total => `总计 ${total} 条`
            listPaginationNew.current = params.page

            const listData = result.data.list

            setListData(listData)
            setSoSO(params);
            setListLoading(false)
            setListPagination(listPaginationNew)
        }
    }

    async function Submit(values) {
        let result = await ajaxAdminOrganizationSetAdd(values)
        if (result.code === 1) {
            initFetch()
            setVisible(false);
            message.success('操作成功')
        }
    }

    // const SoSubmit = (values) => {
    //     setSoSO(values);
    //     values.page = 1
    //     fetch(values).then()
    // }

    const PassWord = async (values) => {
        let result = await ajaxAdminOrganizationSetUserNamePasswordSave(values)
        if (result.code === 1) {
            setListLoading(false);
            message.success('操作成功')
        }
    }

    const onSyncInfo = async () => {
        const apiToken = formRef.current.getFieldValue("api_token")
        if (apiToken !== ""){
            let result = await ajaxAdminOrganizationGetSyncTelegramInfo({"bot_token":apiToken})
            if (result.code === 1) {
                const v = formRef.current.getFieldsValue();
                v.bot_name = result.data.first_name
                v.bot_tg = "@"+result.data.username
                v.bot_id =  result.data.id
                formRef.current.setFieldsValue(v)
                message.success('获取成功')
            }
        }
    }

    const handleTableChange = (pagination) =>{
        soSO.page = pagination.current
        fetch(soSO).then()
    }

    const columns = [
        {
            title: '站点ID',
            dataIndex: 'orgid',
        },  {
            title: '站点名称',
            dataIndex: 'name',
        },  {
            title: '余额',
            dataIndex: 'balance',
        },  {
            title: '总充值',
            dataIndex: 'balance_total',
        },   {
            title: '管理员',
            dataIndex: 'orgid',
            render: (text, item) => {
                return (
                    <> admin-{text} </>
                );
            },
        },  {
            title: '状态',
            dataIndex: 'status',
            render: (text, item) => {
                return (
                    <> {GetStatusName(item.status)} </>
                );
            },
        },   {
            title: '添加时间',
            dataIndex: 'created_at'
        },  {
            title: '更新时间',
            dataIndex: 'updated_at'
        }, {
            title: "操作",
            render: (text, item) => {
                return (
                    <Space>
                        <Popconfirm
                            title="你是否确认重置管理员密码为123456?"
                            onConfirm={()=>{
                                setListLoading(true);
                                PassWord({username: 'admin-'+item.orgid,password:123456})
                            }}
                            okText="确认"
                            cancelText="取消"
                        >
                            <Button type={'link'} >重置密码</Button>
                        </Popconfirm>
                    </Space>
                );
            },
        }]

    return <>
        <Card  title="列表">
            <Button type="primary" style={{marginBottom: 15, width: 160}} onClick={()=>{
                setOperate("添加")
                formRef.current.resetFields()
                setVisible(true);
            }}> 添加 </Button>

            <Table
                columns={columns}
                rowKey="id"
                pageSize={15}
                dataSource={listData}
                pagination={listPagination}
                loading={listLoading}
                onChange={handleTableChange}
            />
        </Card>


        <Modal open={visible} width={700} title={operate} forceRender={true} footer={null} onCancel={() => {setVisible(false)}}>
            <Form labelCol={{ span: 4 }} wrapperCol={{ span: 20 }} onFinish={Submit}  ref={formRef} {...props.layout}>
                <Form.Item name="id" hidden={true}><Input /></Form.Item>
                <Form.Item label="站点名称" name="name"  rules={[{required: true, message: '不能为空'}]}>
                    <Input placeholder={'站点名称不能为空'}/>
                </Form.Item>
                <Form.Item label="站点账户密码" name="password"  rules={[{required: true, message: '不能为空'}]}>
                    <Input placeholder={'站点密码不能为空'}/>
                </Form.Item>
                <Form.Item label="机器人API Token" name="api_token"  rules={[{required: true, message: '不能为空'}]}>
                    <Space.Compact style={{ width: '100%' }}>
                        <Input placeholder={'token不能为空'}/>
                        <Button onClick={()=>{onSyncInfo()}} type="primary">同步官方</Button>
                    </Space.Compact>
                </Form.Item>

                <Form.Item label="机器人 ID" name="bot_id" rules={[{required: true, message: '不能为空'}]}>
                    <Input disabled/>
                </Form.Item>

                <Form.Item label="机器人用户名" name="bot_name" rules={[{required: true, message: '不能为空'}]}>
                    <Input/>
                </Form.Item>
                <Form.Item label="机器人TG号：" name="bot_tg" rules={[{required: true, message: '不能为空'}]}>
                    <Input/>
                </Form.Item>

                <Form.Item label="代理商余额：" name="balance" rules={[{required: true, message: '不能为空'}]}>
                    <Input/>
                </Form.Item>
                <Row justify="end">
                    <Col>
                        <Space>
                            <Button style={{width:240}} type="primary" htmlType="submit">
                                保存
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </Modal>
    </>
}