import {BrowserRouter, Route, Routes} from 'react-router-dom';
import NotFound from './pages/common/404'
import AdminRouter from "./pages/common/home/main";
import HomeRouter from "./pages/public/home";
import PublicRouter from "./pages/router/public";
import H5Router from "./pages/router/adminh5";

import React from 'react';
import { ConfigProvider } from "antd"
import 'dayjs/locale/zh-cn'
import zhCN from 'antd/lib/locale/zh_CN';
export default function App() {
    return (
        <ConfigProvider
                locale={zhCN}
                theme={{
                    components: {
                        Calendar: {
                            /* 这里是你的组件 token */
                            // itemActiveBg:'#fff',
                        },
                    },
                }}
            >
            <BrowserRouter>
                <Routes>
                    <Route path='/admin/*' element={<AdminRouter/>}/>
                    <Route path='/public/*' element={<PublicRouter/>}/>
                    <Route path='/adminh5/*' element={<H5Router/>}/>
                    <Route path='/' element={<HomeRouter/>}/>
                    <Route path='*' element={<NotFound/>}/>
                </Routes>
            </BrowserRouter>
        </ConfigProvider>
    );
}
