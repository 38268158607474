import {useLocation, useNavigate, useParams, useSearchParams,} from 'react-router-dom'
import React from 'react';
export default function withRouter(WrapperedCpn) {
    return function (props) {
        // 在函数组件中通过hook拿到navigate对象
        const uNavigate = useNavigate()
        // 获取navigate中state传的参数
        const uLocation = useLocation()

        const uParams = useParams();
        const uSearchParams = useSearchParams();
        // 将获取到的navigate放到一个对象中
        const router = {uNavigate, uLocation, uParams, uSearchParams}

        return <WrapperedCpn {...props} router={router}/>
    }
}