import {CloseCircleOutlined, SmileOutlined} from '@ant-design/icons';
import {
    Button,
    Card,
    Col,
    Form,
    Input,
    message,
    Modal,
    QRCode,
    Result,
    Row,
    Space,
    Spin,
    theme,
    Typography
} from 'antd';
import React, {useEffect, useState} from "react";
import {ajaxAdminOrganizationGetInfo, ajaxAdminOrganizationSetBalanceAddressSave} from "../../../../api";
import {GetValidateAddress} from "../../../../utils/define";
import BalanceRechargeList from "./list";

const { Paragraph, Text } = Typography;
const { useToken } = theme;
export default function BalanceRecharge(props){
    const { token } = useToken();
    const [data, setData] = useState({info:{},balance_address:""});
    const [loading, setLoading] = useState(false);
    const [visible,setVisible] = useState(false);
    const [visibleList,setVisibleList] = useState(false);
    useEffect(() => {
        initFetch()
        return () => {
            // 清理操作，比如取消网络请求或者清除定时器等
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // 空数组意味着仅在组件挂载时执行这个effect


    const initFetch = () => {
        fetch().then()
    }
    const fetch = async (params = {}) => {
        setLoading(true);
        let result = await ajaxAdminOrganizationGetInfo()
        if (result.code === 1) {
            setLoading(false);
            setData(result.data)
            if (result.data.info.balance_address === "") {
                setVisible(true)
            }
            message.success('获取成功')
        }
    }

    const Submit = async (values) => {
        values.balance_address = values.balance_address.trim()
        const res = await ajaxAdminOrganizationSetBalanceAddressSave(values)
        if (res.code === 1) {
            message.success('修改成功')
            initFetch()
            setVisible(false)
        }
    }



    return <>
        <Spin spinning={loading}>
            <Card title="代理商" extra={<Button style={{width:240}} onClick={()=>{setVisibleList(true)}} type="primary">消费记录</Button>}>
            <Result
                icon={<SmileOutlined/>}
                title={"你好 "+data.info.name+",用于充值代理商余额!"}
                extra={<div>余额：<Button type="like">{data.info.balance}</Button> 总充值：<Button type="like">{data.info.balance_total}</Button><br/><br/>支付地址：{data.info.balance_address} <Button onClick={()=>{ setVisible(true)}} type="link">修改</Button><br/><p style={{marginRight:68}}>充值地址：{data.balance_address}</p><br/><br/> <QRCode style={{margin: "auto"}} value={data.balance_address} color={token.colorSuccessText} bgColor={token.colorBgLayout} /> 二维码</div>}
            >

            <div className="desc">
                <Paragraph>
                    <Text
                        strong
                        style={{
                            fontSize: 16,
                        }}
                    >
                        有一些主要注意的内容:
                    </Text>
                </Paragraph>
                <Paragraph>
                    <CloseCircleOutlined className="site-result-demo-error-icon"/> 如果是首次充值，需要充值金额大于或等于500TRX
                </Paragraph>
                <Paragraph>
                    <CloseCircleOutlined className="site-result-demo-error-icon"/> 非首次充值，需要最小充值大于或者等于50TRX
                </Paragraph>
                <Paragraph>
                    <CloseCircleOutlined className="site-result-demo-error-icon"/> 余额为0机器人默认返回暂停服务
                </Paragraph>
            </div>
            </Result>
            </Card>
        </Spin>
        <Modal open={visible} width="70%" title={"修改支付地址"} forceRender={true} footer={null}>
            <Form layout="vertical"  onFinish={Submit}>
                <Form.Item label="支付地址（支付过程中，请勿修改地址）" name="balance_address" rules={GetValidateAddress()}>
                    <Input placeholder={'填写正确的地址'}/>
                </Form.Item>
                <Row justify="end">
                    <Col>
                        <Space>
                            <Button style={{width:240}} type="primary" htmlType="submit">
                                保存
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </Modal>

        <Modal open={visibleList} width="70%" title={"消费记录"}  footer={null} onCancel={() => {setVisibleList(false)}}>
            <BalanceRechargeList />
        </Modal>
    </>
}