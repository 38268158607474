import {ErrorBlock, Grid, List, Mask, Popup, SpinLoading} from "antd-mobile";
import {
    GetCategoryName,
    GetTronOrderStatusName,
    GetTronOrderTypesName
} from "../../../../../utils/define";
import React, {useEffect, useState} from "react";
import {ajaxAdminTronGetOrderList} from "../../../../../api";
import {useParams} from "react-router-dom";
import {Descriptions} from "antd";


export default function TronOrderList(props){
    const param = useParams();
    const [listData, setListData] = useState([]);
    const [listLoading, setListLoading] = useState(false);
    const [visibleAdd, setVisibleAdd] = useState(false);
    const [info, setInfo] = useState({});

    useEffect(() => {
        initFetch()
        return () => {
            // 清理操作，比如取消网络请求或者清除定时器等
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.types]); // 空数组意味着仅在组件挂载时执行这个effect

    const initFetch = () => {
        fetch().then()
    }

    const fetch = async (params = {}) => {
        params.types = param.types
        console.log("props.types",props.types)
        if (props.types){
            params.types = props.types
        }
        setListLoading(true)
        const result = await ajaxAdminTronGetOrderList(params)
        if (result.code === 1) {
            setListData(result.data.list)
            setListLoading(false)
        }
    }

    return <>
        <Mask visible={listLoading}>
            <div style={{position: "absolute", top: "50%", color: '#00b578', left: "50%", display: "flex",}}>
                <SpinLoading color='#ffffff' />
            </div>
        </Mask>

        {listData.length < 1 ? <ErrorBlock status='empty' style={{height: "100%"}}  />:""}


        <List>
            {listData.map(item => (
                <List.Item
                    onClick={() => {
                        setInfo(item)
                        setVisibleAdd(true)
                    }}
                    key={item.id}
                    description={ <Grid columns={3} gap={8}>
                        <Grid.Item>
                            类别：{GetTronOrderTypesName(item.types)}
                        </Grid.Item>
                        <Grid.Item>
                            金额：{item.amount} {GetCategoryName(item.category)}
                        </Grid.Item>
                        <Grid.Item>
                            状态：{GetTronOrderStatusName(item.status)}
                        </Grid.Item>
                        <Grid.Item span={3}>
                            转出：{item.form}
                        </Grid.Item>
                        <Grid.Item span={3}>
                            交易时间：{item.timestamp}
                        </Grid.Item>
                    </Grid>}
                >
                    {item.form}
                </List.Item>
            ))}
        </List>

        <Popup
            visible={visibleAdd}
            showCloseButton
            onMaskClick={() => {
                setVisibleAdd(false)
            }}
            position='left'
            bodyStyle={{width: '60vw'}}
            onClose={() => {
                setVisibleAdd(false)
            }}
        >
            <div style={{padding: '40px 20px 20px'}}>
                <Descriptions column={1} bordered size="small">
                    <Descriptions.Item label="类别">{GetTronOrderTypesName(info.types)}</Descriptions.Item>
                    <Descriptions.Item label="转出">{info.form}</Descriptions.Item>
                    <Descriptions.Item label="转入">{info.to}</Descriptions.Item>
                    <Descriptions.Item label="金额类型">{GetCategoryName(info.category)}</Descriptions.Item>
                    <Descriptions.Item label="金额">{info.amount}</Descriptions.Item>
                    <Descriptions.Item label="交易时间">{info.timestamp}</Descriptions.Item>
                    <Descriptions.Item label="交易txid">{info.txid}</Descriptions.Item>
                    <Descriptions.Item label="状态">{GetTronOrderStatusName(info.status)}</Descriptions.Item>
                    <Descriptions.Item label="处理说明">{info.process_content}</Descriptions.Item>
                    <Descriptions.Item label="处理txid">{info.process_txid}</Descriptions.Item>
                    <Descriptions.Item label="添加时间">{info.created_at}</Descriptions.Item>
                    <Descriptions.Item label="更新时间">{info.updated_at}</Descriptions.Item>
                </Descriptions>
            </div>
        </Popup>
    </>
}