import {Button, Col, Descriptions, Form, Input, message, Modal, Radio, Row, Space} from "antd";
import { InfoCircleOutlined } from '@ant-design/icons';
import React, {useEffect} from "react";
import {ajaxAdminUserSetRecharge} from "../../../../api";
const { TextArea } = Input;

export default function Recharge(props) {
    const formRef = React.createRef()
    const SendSubmit = async (values) => {
        values.telegram_id = props.visibleInfo.telegram_id;
        const res = await ajaxAdminUserSetRecharge(values)
        if (res.code === 1) {
            formRef.current.resetFields();
            props.setVisible(false)
            props.init()
            message.success(res.msg)
        }
    }

    useEffect(() => {
        if (props.visible === true && props.visibleInfo.telegram_id){
            //formRef.current.setFieldsValue([{telegram_id:props.visibleInfo.telegram_id}]);
        }

        return () => {
            // 清理操作，比如取消网络请求或者清除定时器等
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // 空数组意味着仅在组件挂载时执行这个effect

    return <>
        <Modal open={props.visible} width="70%" title={"发送消息"} forceRender={true} footer={null} onCancel={() => {props.setVisible(false)}}>
            <Descriptions title="" size="small">
                <Descriptions.Item label="TG用户ID">{props.visibleInfo.telegram_id}</Descriptions.Item>
                <Descriptions.Item label="TG用户名称">{props.visibleInfo.user_name}</Descriptions.Item>
                <Descriptions.Item label=""></Descriptions.Item>
                <Descriptions.Item label="TRX">{props.visibleInfo.trx}</Descriptions.Item>
                <Descriptions.Item label="USDT">{props.visibleInfo.usdt}</Descriptions.Item>
            </Descriptions>
            <br/><br/>
            <Form onFinish={SendSubmit} layout="vertical" labelAlign="right" ref={formRef}>
                <Form.Item rules={[{required: true, message: '不能为空'}]} label="充值类型" name="category">
                    <Radio.Group value={1}>
                        <Radio value={1}>TRX</Radio>
                        <Radio value={2}>USDT</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item rules={[{required: true, message: '不能为空'}]} name="amount" label="充值金额(如果需要扣减余额，请输入负数.)"  tooltip={{ title: '例如：输入5，则是增加5余额，输入-5，则是扣减5余额', icon: <InfoCircleOutlined /> }} >
                    <Input/>
                </Form.Item>
                <Form.Item label="备注" name="notes">
                    <TextArea rows={4}  placeholder="长度不能超过300"  maxLength={300}/>
                </Form.Item>
                <Row justify="end">
                    <Col>
                        <Space>
                            <Button style={{width:240}} type="primary" htmlType="submit">
                                充值
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </Modal>
    </>
}