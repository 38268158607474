import React from "react";

import { createFromIconfontCN } from '@ant-design/icons';

const IconFont = createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/font_1836374_3osg6fno7kb.js',
});

// export default {
//     Icon: {
//         "notice": <IconFont type="tn-notice" />
//     }
// }

/*
    fontSize 大小
    color 颜色
    rotate 旋转
 */
export function ApiIcon(param) {
    return getIcon(param, "tn-api");
}
export function BugIcon(param) {
    return getIcon(param, "tn-bug");
}
export function PowerIcon(param) {
    return getIcon(param, "tn-power");
}
export function PlatformIcon(param) {
    return getIcon(param, "tn-platform");
}
export function NoticeIcon(param) {
    return getIcon(param, "tn-notice");
}
export function UserIcon(param) {
    return getIcon(param, "tn-user");
}
export function DownIcon(param) {
    return getIcon(param, "tn-down");
}
export function RightIcon(param) {
    return getIcon(param, "tn-right");
}
export function UpIcon(param) {
    return getIcon(param, "tn-up");
}
export function LeftIcon(param) {
    return getIcon(param, "tn-left");
}
export function PasswordIcon(param) {
    return getIcon(param, "tn-password");
}
export function SignoutIcon(param) {
    return getIcon(param, "tn-signout");
}
export function UserConfigIcon(param) {
    return getIcon(param, "tn-user-config");
}
export function MenuUnfoldIcon(param) {
    return getIcon(param, "tn-menu-unfold");
}
export function MenuFoldIcon(param) {
    return getIcon(param, "tn-menu-fold");
}
export function NoticeTextIcon(param) {
    return getIcon(param, "tn-notice-text");
}
export function NoticeLinkIcon(param) {
    return getIcon(param, "tn-notice-link");
}

export function PlaceholderIcon(param) {
    return getIcon(param, "tn-placeholder");
}
export function LikeIcon(param) {
    return getIcon(param, "tn-like");
}
export function UnlikeIcon(param) {
    return getIcon(param, "tn-unlike");
}
export function ShrinkIcon(param) {
    return getIcon(param, "tn-shrink");
}
export function SikeLinkIcon(param) {
    return getIcon(param, "tn-like");
}
export function VerticalRightIcon(param) {
    return getIcon(param, "tn-vertical-right");
}
export function VerticalLeftIcon(param) {
    return getIcon(param, "tn-vertical-left");
}
export function FullscreenIcon(param) {
    return getIcon(param, "tn-fullscreen");
}
export function FullscreenExitIcon(param) {
    return getIcon(param, "tn-fullscreen-exit");
}
export function ArrowrightIcon(param) {
    return getIcon(param, "tn-arrow-right");
}
export function ArrowupIcon(param) {
    return getIcon(param, "tn-arrow-up");
}
export function ArrowLeftIcon(param) {
    return getIcon(param, "tn-arrow-left");
}
export function ArrowDownIcon(param) {
    return getIcon(param, "tn-arrow-down");
}
export function UploadIcon(param) {
    return getIcon(param, "tn-upload");
}
export function ColumHeightIcon(param) {
    return getIcon(param, "tn-colum-height");
}
export function VerticalAlignDownIcon(param) {
    return getIcon(param, "tn-vertical-align-down");
}
export function VerticalAlignUpIcon(param) {
    return getIcon(param, "tn-vertical-align-up");
}
export function DelIcon(param) {
    return getIcon(param, "tn-del");
}
export function BestIcon(param) {
    return getIcon(param, "tn-best");
}
export function ProblemIcon(param) {
    return getIcon(param, "tn-problem");
}
export function StudyIcon(param) {
    return getIcon(param, "tn-study");
}
export function ConsultingIcon(param) {
    return getIcon(param, "tn-consulting");
}
export function StatisticsIcon(param) {
    return getIcon(param, "tn-statistics");
}
export function MeetingManageIcon(param) {
    return getIcon(param, "tn-meeting-manage");
}
export function PlanmanageIcon(param) {
    return getIcon(param, "tn-planmanage");
}
export function TaskCmoduleManageIcon(param) {
    return getIcon(param, "tn-task-cmodule-manage");
}
export function UsersIcon(param) {
    return getIcon(param, "tn-users");
}
export function InvestmentGuidesIcon(param) {
    return getIcon(param, "tn-investment-guides");
}
export function AdministrationIcon(param) {
    return getIcon(param, "tn-administration");
}
export function OutletsIcon(param) {
    return getIcon(param, "tn-outlets");
}
export function MaintenanceIcon(param) {
    return getIcon(param, "tn-maintenance");
}
export function QueryIcon(param) {
    return getIcon(param, "tn-query");
}
export function FeedbackIcon(param) {
    return getIcon(param, "tn-feedback");
}
export function ReplyIcon(param) {
    return getIcon(param, "tn-reply");
}
export function RecordIcon(param) {
    return getIcon(param, "tn-record");
}
export function SheetIcon(param) {
    return getIcon(param, "tn-sheet");
}
export function PermitIcon(param) {
    return getIcon(param, "tn-permit");
}
export function VolunteersIcon(param) {
    return getIcon(param, "tn-volunteers");
}
export function MenuIcon(param) {
    return getIcon(param, "tn-menu");
}
export function AuditIcon(param) {
    return getIcon(param, "tn-audit");
}
export function ConfigIcon(param) {
    return getIcon(param, "tn-config");
}
export function PullIcon(param) {
    return getIcon(param, "tn-pull");
}
export function ThepublicIcon(param) {
    return getIcon(param, "tn-thepublic");
}
export function NoticeAIcon(param) {
    return getIcon(param, "tn-notice-a");
}
export function UserGroupConfigIcon(param) {
    return getIcon(param, "tn-user-group-config");
}
export function HomeIcon(param) {
    return getIcon(param, "tn-home");
}
export function SystemIcon(param) {
    return getIcon(param, "tn-system");
}
export function AppointmentIcon(param) {
    return getIcon(param, "tn-appointment");
}
export function EdIcon(param) {
    return getIcon(param, "tn-ed");
}
export function NotebookIcon(param) {
    return getIcon(param, "tn-notebook");
}
export function ErrorIcon(param) {
    return getIcon(param, "tn-error");
}
export function CheckIcon(param) {
    return getIcon(param, "tn-check");
}
export function AddIcon(param) {
    return getIcon(param, "tn-add");
}
export function AboutIcon(param) {
    return getIcon(param, "tn-about");
}
export function CopyIcon(param) {
    return getIcon(param, "tn-copy");
}
export function OrganizationIcon(param) {
    return getIcon(param, "tn-organization");
}
export function OrganizationAIcon(param) {
    return getIcon(param, "tn-organization-a");
}
export function RegisterIcon(param) {
    return getIcon(param, "tn-register");
}
export function CollectionIcon(param) {
    return getIcon(param, "tn-collection");
}
export function CircleofFriendsIcon(param) {
    return getIcon(param, "tn-circleof-friends");
}
export function UserGroupIcon(param) {
    return getIcon(param, "tn-user-group");
}
export function LoginIcon(param) {
    return getIcon(param, "tn-login");
}
export function FolderIcon(param) {
    return getIcon(param, "tn-folder");
}
export function ChooseIcon(param) {
    return getIcon(param, "tn-choose");
}
export function ThelabelIcon(param) {
    return getIcon(param, "tn-thelabel");
}
export function TrendIcon(param) {
    return getIcon(param, "tn-trend");
}
export function DownloadIcon(param) {
    return getIcon(param, "tn-download");
}
export function ExcelIcon(param) {
    return getIcon(param, "tn-excel");
}
export function PdfIcon(param) {
    return getIcon(param, "tn-pdf");
}
export function WordIcon(param) {
    return getIcon(param, "tn-word");
}
export function ServiceBackdropIcon(param) {
    return getIcon(param, "tn-service-backdrop");
}
export function DeleteIcon(param) {
    return getIcon(param, "tn-delete");
}
export function SearchIcon(param) {
    return getIcon(param, "tn-search");
}
export function ApplicationIcon(param) {
    return getIcon(param, "tn-application");
}
export function EefreshIcon(param) {
    return getIcon(param, "tn-refresh");
}
export function CloudIcon(param) {
    return getIcon(param, "tn-cloud");
}
export function HospitalIcon(param) {
    return getIcon(param, "tn-hospital");
}
export function LinkIcon(param) {
    return getIcon(param, "tn-link");
}
export function ServiceIcon(param) {
    return getIcon(param, "tn-service");
}
export function SigninIcon(param) {
    return getIcon(param, "tn-signin");
}


export function PositionBackdropIcon(param) {
    return getIcon(param, "tn-position-backdrop");
}
export function DownloadBackdropIcon(param) {
    return getIcon(param, "tn-download-backdrop");
}
export function InspectBackdropIcon(param) {
    return getIcon(param, "tn-inspect-backdrop");
}
export function MailBackdropIcon(param) {
    return getIcon(param, "tn-mail-backdrop");
}
export function TimeBackdropIcon(param) {
    return getIcon(param, "tn-time-backdrop");
}
export function ThelabelBackdropIcon(param) {
    return getIcon(param, "tn-thelabel-backdrop");
}
export function TaskBackdropIcon(param) {
    return getIcon(param, "tn-task-backdrop");
}
export function RemindBackdropIcon(param) {
    return getIcon(param, "tn-remind-backdrop");
}
export function ImgBackdropIcon(param) {
    return getIcon(param, "tn-img-backdrop");
}
export function TaskListBackdropIcon(param) {
    return getIcon(param, "tn-task-list-backdrop");
}
export function RechargeBackdropIcon(param) {
    return getIcon(param, "tn-recharge-backdrop");
}
export function CalendarBackdropIcon(param) {
    return getIcon(param, "tn-calendar-backdrop");
}
export function SettingsBackdropIcon(param) {
    return getIcon(param, "tn-settings-backdrop");
}
export function AddBackdropIcon(param) {
    return getIcon(param, "tn-add-backdrop");
}
export function StartBackdropIcon(param) {
    return getIcon(param, "tn-start-backdrop");
}
export function MessageBackdropIcon(param) {
    return getIcon(param, "tn-message-backdrop");
}
export function PptBackdropIcon(param) {
    return getIcon(param, "tn-ppt-backdrop");
}
export function WordBackdropIcon(param) {
    return getIcon(param, "tn-word-backdrop");
}
export function FolderBackdropIcon(param) {
    return getIcon(param, "tn-folder-backdrop");
}
export function PdfBackdropIcon(param) {
    return getIcon(param, "tn-pdf-backdrop");
}
export function NoticeBackdropIcon(param) {
    return getIcon(param, "tn-notice-backdrop");
} export function TupiantubiaoIcon(param) {
    return getIcon(param, "tn-tupiantubiao");
} export function TnCuowu(param) {
    return getIcon(param, "tn-cuowu");
}


export function getTypeIcon(type) {
    return getIcon({}, 'tn-' + type)
}

export function getIcon(param, type) {
    return <IconFont type={type || "tn-moren"} style={param.style || { 'fontSize': 16 }} className={param.className || ''} onClick={param.onClick || null} rotate={param.rotate || ''} />
}