import React from 'react';
import {Route, Routes} from "react-router-dom";
import NotFound from '../../common/404'

import Home from "../../admin/home";
import TelegramEd from "../../admin/telegram/ed";
import RechargePackageList from "../../admin/recharge/package/list";
import TenancyPackageList from "../../admin/tenancy/package/list";
import RechargeOrderList from "../../admin/recharge/order/list";
import UserList from "../../admin/user/list";
import BalanceList from "../../admin/balance/list";
import TelegramGroup from "../../admin/telegram/group";
import TenancyOrderList from "../../admin/tenancy/order/list";
import OrganizationList from "../../admin/organization/list";
import BalanceRecharge from "../../admin/balance/recharge";
import AdminConfig from "../../admin/config";
import TronOrderList from "../../admin/tron/order/list";
import PassWord from "../../admin/organization/password";
import BalanceRechargeList from "../../admin/balance/recharge/list";
import StatisticsIndex from "../../admin/statistics/index";


export default function Index() {
    return <>
        <Routes>
            <Route path="/telegram/ed" element={<TelegramEd/>}/>
            <Route path="/recharge/package/list" element={<RechargePackageList/>}/>
            <Route path="/recharge/order/list" element={<RechargeOrderList/>}/>
            <Route path="/tenancy/package/list" element={<TenancyPackageList/>}/>
            <Route path="/organization/password" element={<PassWord/>}/>
            <Route path="/telegram/group" element={<TelegramGroup/>}/>
            <Route path="/tenancy/order/list" element={<TenancyOrderList/>}/>
            <Route path="/tron/order/list/:types" element={<TronOrderList/>}/>
            <Route path="/user/list" element={<UserList/>}/>
            <Route path="/balance/recharge/list" element={<BalanceRechargeList/>}/>
            <Route path="/statistics/index" element={<StatisticsIndex/>}/>
            <Route path="/organization/list" element={<OrganizationList/>}/>
            <Route path="/balance/list" element={<BalanceList/>}/>
            <Route path="/balance/recharge" element={<BalanceRecharge/>}/>
            <Route path="/config/ed" element={<AdminConfig/>}/>
            <Route path="/" element={<Home/>}/>
            <Route path="/*" element={<NotFound/>}/>
        </Routes>
    </>
}