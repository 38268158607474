import React, {useEffect, useState} from "react";
import {ajaxAdminRechargeGetOrderList} from "../../../../../api";
import {
    GetCategoryName,
    GetRechargeStatusName, GetWhetherName
} from "../../../../../utils/define";
import {Button, Card, Descriptions, Modal, Space, Table} from "antd";


export default function RechargeOrderList(props){
    const [info, setInfo] = useState({});
    const [listData, setListData] = useState([]);
    const [listPagination, setListPagination] = useState({showSizeChanger: false});
    const [listLoading, setListLoading] = useState(false);
    const [soSO,setSoSO] = useState({});
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        initFetch()
        return () => {
            // 清理操作，比如取消网络请求或者清除定时器等
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // 空数组意味着仅在组件挂载时执行这个effect

    const initFetch = () => {
        fetch().then()
    }

    const fetch = async (params = {}) => {
        setListLoading(true)
        params.page = params.page ? params.page : 1
        const result = await ajaxAdminRechargeGetOrderList(params)
        if (result.code === 1) {
            const listPaginationNew = { ...listPagination }
            listPaginationNew.total = result.data.total
            listPaginationNew.pageSize = result.data.limit
            listPaginationNew.showTotal = total => `总计 ${total} 条`
            listPaginationNew.current = params.page

            const listData = result.data.list

            setListData(listData)
            setSoSO(params);
            setListLoading(false)
            setListPagination(listPaginationNew)
        }
    }

    // const SoSubmit = (values) => {
    //     setSoSO(values);
    //     values.page = 1
    //     fetch(values).then()
    // }

    const handleTableChange = (pagination) =>{
        soSO.page = pagination.current
        fetch(soSO).then()
    }

    const columns = [
        {
            title: '订单ID',
            dataIndex: 'trade_no',
        },  {
            title: '用户',
            dataIndex: 'telegram_id',
            render: (text, item) => {
                return (
                    item.telegram_id?<>{item.telegram_id}（ {item.user_name} ）</>:""
                );
            }
        },{
            title: '付款金额',
            dataIndex: 'pay_amount',
            render: (text, item) => {
                return (
                    <>{item.pay_amount} {GetCategoryName(item.category)} </>
                );
            },
        }, {
            title: '超时时间',
            dataIndex: 'invalid_time'
        }, {
            title: '状态',
            dataIndex: 'status',
            render: (text, item) => {
                return (
                    <> {GetRechargeStatusName(item.status)} </>
                );
            },
        }, {
            title: '更新时间',
            dataIndex: 'updated_at'
        }, {
            title: "操作",
            render: (text, item) => {
                return (
                    <Space>
                        <Button type={'link'} onClick={() => {
                            setInfo(item)
                            setVisible(true)
                        }}>详情</Button>
                    </Space>
                );
            },
        }]
    return <>
        <Card title="列表">
            <Table
                columns={columns}
                rowKey="id"
                pageSize={15}
                dataSource={listData}
                pagination={listPagination}
                loading={listLoading}
                onChange={handleTableChange}
            />
        </Card>
        <Modal open={visible} width={700} title={"详情"} forceRender={true} footer={null} onCancel={() => {setVisible(false)}}>
            <Descriptions column={1} bordered size="small">
                <Descriptions.Item label="订单号">{info.trade_no}</Descriptions.Item>
                <Descriptions.Item label="用户">{info.telegram_id}（ {info.user_name} ）</Descriptions.Item>
                <Descriptions.Item label="充值类型">{GetCategoryName(info.category)}</Descriptions.Item>
                <Descriptions.Item label="充值金额">{info.amount}</Descriptions.Item>
                <Descriptions.Item label="付款金额">{info.pay_amount}</Descriptions.Item>
                <Descriptions.Item label="超时时间">{info.invalid_time}</Descriptions.Item>
                <Descriptions.Item label="状态">{GetRechargeStatusName(info.status)}</Descriptions.Item>
                <Descriptions.Item label="是否通知">{GetWhetherName(info.is_notice)}</Descriptions.Item>
                <Descriptions.Item label="添加时间">{info.created_at}</Descriptions.Item>
                <Descriptions.Item label="更新时间">{info.updated_at}</Descriptions.Item>
            </Descriptions>
        </Modal>
    </>
}