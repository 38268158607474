import React, {Component} from "react"
import {Navigate, Route, Routes} from 'react-router-dom'
import storageUtils from "../../../utils/storage"
import './main.css'
import {Layout,Divider} from "antd";
import LayoutLeft from "../../../components/layouts/left";
import LayoutHead from "../../../components/layouts/head";
import withRouter from "../../../utils/withRouter";
import AdminRouter from "../../router/admin";


const {Content} = Layout;

class Home extends Component {

    formRef = React.createRef()
    state = {
        collapsed: false,
        visible: false,
        selectedKey: '',
        xzqhList: []
    }

    selectedKey = (selectedKey) => {
        this.setState({
            selectedKey: selectedKey
        })
    }

    componentDidMount() {


    }


    render() {
        /**
         * 检查登陆
         */
            //
        const token = storageUtils.getToken();
        const user = storageUtils.getUser();
        if (!token || token.length < 1 || !user) {
            return <Routes>
                <Route path="/" element={<Navigate to={`/public/login`}/>}/>
            </Routes>
        }
        return (
            <Layout id="components-layout-demo-custom-trigger">
                <LayoutHead selectedKey={this.selectedKey} collapsed={this.state.collapsed} setCollapsed={(collapsed) => {this.setState({collapsed: collapsed})}}/>
                <Divider style={{margin:'0px'}} />
                <Layout>
                    <LayoutLeft collapsed={this.state.collapsed} selectedKey={this.state.selectedKey} setCollapsed={(collapsed) => {this.setState({collapsed: collapsed})}}/>
                    <Content style={{margin: '24px 16px',padding: "", background:  ''  }}>
                        <Routes>
                            <Route path='*' element={<AdminRouter/>}/>
                        </Routes>
                    </Content>
                </Layout>
            </Layout>
        )
    }
}

export default withRouter(Home);