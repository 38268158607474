import {Affix, Button, Card, Checkbox, Col, Form, Input, InputNumber, message, Row, Space, Spin, Tooltip} from "antd";
import React, {useEffect, useState} from "react";
import {
    ajaxAdminConfigGetGroupList,
    ajaxAdminConfigGetInfo,
    ajaxAdminConfigSetEd,
    ajaxAdminOrganizationGetSyncTelegramInfo
} from "../../../../api";
import {GetValidateAddress} from "../../../../utils/define";

const formRef = React.createRef()
export default function TelegramEd() {
    const [info, setInfo] = useState({});
    const [loading, setLoading] = useState(false);
    const [groupList, setGroupList] = useState([]);

    const initFetch = () => {
        fetch().then()
        fetchGroup().then()
    }

    const fetch = async () => {
        let result = await ajaxAdminConfigGetInfo()
        console.log(result)
        if (result.code === 1) {
            formRef.current.setFieldsValue(result.data)
            setInfo(result.data)
        }
        setLoading(false)
    }

    const fetchGroup = async () => {
        const result = await ajaxAdminConfigGetGroupList()
        if (result.code === 1) {
            setGroupList(result.data.list)
        }
    }

    useEffect(() => {
        setLoading(true)
        initFetch()
        return () => {
            // 清理操作，比如取消网络请求或者清除定时器等
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // 空数组意味着仅在组件挂载时执行这个effect

    const Submit = async (values) => {
        values.bot_group = JSON.stringify(values.bot_group)
        values.bot_inline_button = JSON.stringify(values.bot_inline_button)
        const res = await ajaxAdminConfigSetEd(values)
        if (res.code === 1) {
            message.success('保存修改成功')
        }
    }

    const onClick = async () => {
        setLoading(true)
        const apiToken = info.bot_api_token
        if (apiToken !== "") {
            let result = await ajaxAdminOrganizationGetSyncTelegramInfo({"bot_token": apiToken})
            if (result.code === 1) {
                const v = formRef.current.getFieldsValue();
                v.bot_name = result.data.first_name
                v.bot_tg = result.data.username
                formRef.current.setFieldsValue(v)
                message.success('同步成功')
            }
        }
        setLoading(false)
    }

    return <>
        <Spin spinning={loading}>
            <Form onFinish={Submit}  layout="vertical"  ref={formRef}>
                <Form.Item name="id" hidden = {true}><Input /></Form.Item>

                <Card  title="机器人信息" extra={<Button style={{width:240}} onClick={()=>{onClick().then()}} type="primary">同步数据</Button>}>
                    <Row gutter={[16, 24]}>
                        <Col span={12}>
                            <Form.Item label="机器人 ID"  name="bot_id"  >
                                <Input disabled/>
                            </Form.Item>
                            <Form.Item label="机器人token" name="bot_token" >
                                <Input disabled/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="机器人用户名" name="bot_name" rules={[{required: true, message: '不能为空'}]}>
                                <Input disabled/>
                            </Form.Item>
                            <Form.Item label="机器人TG号：" name="bot_tg" rules={[{required: true, message: '不能为空'}]}>
                                <Input disabled/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="客服TG号：" name="bot_service_tg" rules={[{required: true, message: '不能为空'}]}>
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>
                <br/>
                <Card  title="收款配置">
                    <Row gutter={[16, 24]}>
                        <Col span={8}>
                        <Form.Item label="【笔数能量】收款钱包地址" name="payment_tenancy_pen_address"  rules={GetValidateAddress()}>
                            <Input/>
                        </Form.Item>
                        </Col>
                        <Col span={8}>
                        <Form.Item label="【闪兑】收款钱包地址" name="payment_shandui_address" rules={GetValidateAddress()}>
                            <Input/>
                        </Form.Item>
                        </Col>
                        <Col span={8}>
                        <Form.Item label="【小时闪租】收款钱包地址" name="payment_tenancy_address" rules={GetValidateAddress()}>
                            <Input/>
                        </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 24]}>
                        <Col span={12}>
                            <Form.Item label="订单播报群组：" name="bot_group" >
                                <Checkbox.Group style={{ width: '100%' }}>
                                    {groupList?groupList.map((item, _) => {
                                        return  <Checkbox value={item.chat_id}>{item.chat_name}</Checkbox>
                                    }):""}
                                </Checkbox.Group>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="播报内联按钮：" name="bot_inline_button" rules={[{required: true, message: '不能为空'}]}>
                                <Checkbox.Group style={{ width: '100%' }}>
                                    <Checkbox value="1">🔥闪兑TRX</Checkbox>
                                    <Checkbox value="2">🔋能量租赁</Checkbox>
                                    <Checkbox value="3">🛎飞机会员</Checkbox>
                                    <Checkbox value="4">👩‍💻联系客服</Checkbox>
                                </Checkbox.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>
                <br/>
                <Card  title="时间能量价格">
                    <Form.Item label={<Tooltip title="最多支持保留1位小数，注意：此为1笔65000能量价格，不要设置0.1小数结尾的价格，避免与笔数价格冲突">
                        <span>【1小时】能量闪租价格（TRX ）（1笔6.5W能量）</span>
                    </Tooltip>} name="tenancy_time_price" rules={[{required: true, message: '不能为空'}]}>
                        <Input/>
                    </Form.Item>

                    <Form.Item label={<Tooltip title="用户单次可兑换的上限">
                        <span> 【1小时】能量闪租 兑换上限</span>
                    </Tooltip>} name="tenancy_time_max" rules={[{required: true, message: '不能为空'}]}>
                        <InputNumber min={1} max={30} />
                    </Form.Item>
                </Card>
                <br/>
                <Card  title="笔数信息">
                    <Row gutter={[16, 24]}>
                        <Col span={8}>
                            <Form.Item label={<Tooltip title="只支持整数或者以0.5结尾的小数，如果价格与【1小时】能量闪租价格冲突，则优先匹配笔数发货">
                                <span>【1笔】能量TRX价格</span>
                            </Tooltip>} name="tenancy_pen_trx_price" rules={[{required: true, message: ''}]}>
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label={<Tooltip title="只支持保留1位小数">
                                <span>【1笔】能量USDT价格</span>
                            </Tooltip>} name="tenancy_pen_usdt_price" rules={[{required: true, message: '不能为空'}]}>
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                        <Form.Item label={<Tooltip title="用户单次可兑换的上限">
                            <span> 笔数兑换上限</span>
                        </Tooltip>} name="tenancy_pen_max" rules={[{required: true, message: '不能为空'}]}>
                            <InputNumber min={1} max={999} />
                        </Form.Item>
                        </Col>
                    </Row>
                </Card>
                <br/>
                <Card  title="闪兑信息">
                    <Row gutter={[16, 24]}>
                        <Col span={8}>
                            <Form.Item label={<Tooltip title="例如，输入15，就是15%">
                                <span>USDT -> TRX 的利润（百分比）</span>
                            </Tooltip>} name="shandui_usdt_profit" rules={[{required: true, message: ''}]}>
                                <InputNumber min={0} max={100} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label={<Tooltip title="用户单次可兑换的下限">
                                <span> USDT -> TRX 的可兑换下限</span>
                            </Tooltip>} name="shandui_usdt_min" rules={[{required: true, message: '不能为空'}]}>
                                <InputNumber min={0} max={2} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label={<Tooltip title="用户单次可兑换的上限">
                                <span> USDT -> TRX 的可兑换上限</span>
                            </Tooltip>} name="shandui_usdt_max" rules={[{required: true, message: '不能为空'}]}>
                                <InputNumber min={0} max={999} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>
                <br/>
                <Affix offsetBottom={20}>
                    <Row justify="end">
                        <Col>
                            <Space>
                                <Button style={{width:240}} type="primary" htmlType="submit">
                                    保存
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                </Affix>
            </Form>
        </Spin>
        </>
}