import React, {useEffect, useState} from "react";
import {Button, Card, Form, Input, message, Spin} from "antd";

import {ajaxAdminOrganizationSetUserResetPasswordSave} from "../../../../api";
import {useNavigate} from "react-router-dom";


export default function PassWord(props){
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        initFetch()
        return () => {
            // 清理操作，比如取消网络请求或者清除定时器等
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // 空数组意味着仅在组件挂载时执行这个effect

    const initFetch = () => {
        fetch().then()
    }
    const fetch = async () => {

    }

    const onFinish = async (values) => {
        setLoading(true)
        const {password_new, password, password_new_qr} = values;
        if (password_new !== password_new_qr) {
            message.error('二次输入的密码不一致')
            return false;
        }
        let data = {}
        data.password = password;
        data.password_new = password_new;

        const result = await ajaxAdminOrganizationSetUserResetPasswordSave(data);
        if (result.code === 1) {
            message.success(result.msg)
            navigate(`/public/login}`)
        }
        setLoading(false)
    }

    return (
        <Spin tip="加载中..." spinning={loading}>
            <Card  title="修改密码">
            <div>
                <Form onFinish={onFinish} layout="horizontal" labelCol={{span: 3}} wrapperCol={{span: 8}}>
                    <Form.Item name="password" label="旧的密码" rules={[{required: true, message: '参数必须存在!'}]}>
                        <Input.Password/>
                    </Form.Item>
                    <Form.Item name="password_new" label="新的密码" rules={[
                        {required: true, message: '密码必须输入'},
                        {min: 6, message: '密码长度不能小于6位'},
                        {max: 32, message: '密码长度不能大于32位'},
                        {pattern: /^[a-zA-Z0-9_-]+$/, message: '密码必须是英文、数字或下划线组成'},
                    ]}>
                        <Input.Password/>
                    </Form.Item>
                    <Form.Item name="password_new_qr" label="确认新的密码" rules={[
                        {required: true, message: '密码必须输入'},
                        {min: 6, message: '密码长度不能小于6位'},
                        {max: 32, message: '密码长度不能大于32位'},
                        {pattern: /^[a-zA-Z0-9_-]+$/, message: '密码必须是英文、数字或下划线组成'},
                    ]}>
                        <Input.Password/>
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            修改
                        </Button>
                    </Form.Item>
                </Form>
            </div>
            </Card>
        </Spin>
    );
}