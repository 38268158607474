import React, {useEffect, useState} from "react";
import {ajaxAdminBalanceGetList} from "../../../../api";
import {GetBalanceStatusName, GetCategoryName} from "../../../../utils/define";
import {Button, Card, Form, Modal, Radio, Space, Table} from "antd";

const formRef = React.createRef()
export default function BalanceList(props){
    const [listData, setListData] = useState([]);
    const [listPagination, setListPagination] = useState({showSizeChanger: false});
    const [listLoading, setListLoading] = useState(false);
    const [soSO,setSoSO] = useState({});
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        formRef.current.setFieldsValue({types:"","category":""})
        initFetch()
        return () => {
            // 清理操作，比如取消网络请求或者清除定时器等
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // 空数组意味着仅在组件挂载时执行这个effect

    const initFetch = () => {
        fetch().then()
    }

    const fetch = async (params = {}) => {
        setListLoading(true)
        params.page = params.page ? params.page : 1
        const result = await ajaxAdminBalanceGetList(params)
        if (result.code === 1) {
            const listPaginationNew = { ...listPagination }
            listPaginationNew.total = result.data.total
            listPaginationNew.pageSize = result.data.limit
            listPaginationNew.showTotal = total => `总计 ${total} 条`
            listPaginationNew.current = params.page

            const listData = result.data.list

            setListData(listData)
            setSoSO(params);
            setListLoading(false)
            setListPagination(listPaginationNew)
        }
    }

    const SoSubmit = (values) => {
        setSoSO(values);
        values.page = 1
        console.log(values)
        fetch(values).then()
    }

    const handleTableChange = (pagination) =>{
        soSO.page = pagination.current
        fetch(soSO).then()
    }

    const columns = [
         {
            title: '用户',
            dataIndex: 'telegram_id'
        },  {
            title: '类型',
            dataIndex: 'category',
            render: (text, item) => {
                return (
                    <> {GetCategoryName(item.category)} </>
                );
            },
        },  {
            title: '操作金额',
            dataIndex: 'amount'
        },{
            title: '原始金额',
            dataIndex: 'original_price'
        }, {
            title: '类型',
            dataIndex: 'type',
            render: (text, item) => {
                return (
                    <> {GetBalanceStatusName(item.type)} </>
                );
            },
        },{
            title: '备注',
            dataIndex: 'notes'
        },  {
            title: '添加时间',
            dataIndex: 'created_at'
        },  {
            title: '更新时间',
            dataIndex: 'updated_at'
        }, {
            title: "操作",
            render: (text, item) => {
                return (
                    <Space>
                        <Button type={'link'} onClick={() => {
                            setVisible(true)
                        }}>详情</Button>
                    </Space>
                );
            },
        }]
    return <>
        <Card title="搜索">
            <Form layout="inline" onFinish={SoSubmit} labelAlign="right" ref={formRef}>
                <Form.Item label="余额类型" name="category">
                    <Radio.Group  >
                        <Radio value="">全部</Radio>
                        <Radio value="1">TRX</Radio>
                        <Radio value="2">USDT</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item label="变更类型" name="types">
                    <Radio.Group >
                        <Radio value="">全部</Radio>
                        <Radio value="1">收入</Radio>
                        <Radio value="2">支出</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        搜索
                    </Button>
                    <Button htmlType="button" style={{ marginBottom: 0, marginLeft: 5 }} onClick={() => {
                        formRef.current.resetFields();
                    }}>
                        重置
                    </Button>
                </Form.Item>
            </Form>
        </Card>
        <Card title="列表">
            <Table
                columns={columns}
                rowKey="id"
                pageSize={15}
                dataSource={listData}
                pagination={listPagination}
                loading={listLoading}
                onChange={handleTableChange}
            />
        </Card>
        <Modal open={visible} width={700} title={"详情"} forceRender={true} footer={null} onCancel={() => {setVisible(false)}}>
            不知道写点什么
        </Modal>
    </>
}