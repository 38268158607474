import React, {useEffect, useState} from "react";
import {
    ajaxAdminConfigGetAdminInfo, ajaxAdminTenancyGetOrderAdd,
    ajaxAdminTenancyGetOrderList
} from "../../../../../api";
import {
    Mask,
    SpinLoading,
    List,
    Grid,
    Radio,
    FloatingBubble,
    ActionSheet,
    Toast,
    Space,
    Popup,
    Button,
    Stepper,
    Form,
    Input,
    Tag,  ErrorBlock
} from "antd-mobile";
import {GetCategoryName, GetTenancyStatusName, GetValidateAddress} from "../../../../../utils/define";
import {AppstoreOutline} from "antd-mobile-icons";


export default function TenancyOrderList(props){
    const [formRef] = Form.useForm()

    const [info, setInfo] = useState([]);
    const [listData, setListData] = useState([]);
    const [listLoading, setListLoading] = useState(false);
    const [visibleAdd, setVisibleAdd] = useState(false);

    const [appstoreVisible, setAppstoreVisible] = useState(false);
    const [amount, setAmount] = useState(0);


    useEffect(() => {
        initFetch()
        return () => {
            // 清理操作，比如取消网络请求或者清除定时器等
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // 空数组意味着仅在组件挂载时执行这个effect

    const initFetch = () => {
        fetch().then()
        OrderFetch().then()
        //formRef.current.setFieldsValue({"payment":1})
    }

    const OrderFetch = async () => {
        let result = await ajaxAdminConfigGetAdminInfo()
        if (result.code === 1) {
            setInfo(result.data)
        }
    }

    const fetch = async (params = {}) => {
        setListLoading(true)
        params.page = params.page ? params.page : 1
        const result = await ajaxAdminTenancyGetOrderList(params)
        if (result.code === 1) {
            setListData(result.data.list)
            setListLoading(false)
        }
    }

    const addSubmit = async (values) => {
        const res = await ajaxAdminTenancyGetOrderAdd(values)
        if (res.code === 1) {
            setVisibleAdd(false)
            Toast.show({
                icon: 'success',
                content: '保存修改成功',
            })
        }
    }

    const onChange = () => {
        const v = formRef.getFieldsValue()
        console.log(v,info)
        if (v.number !== undefined){
            if (v.payment === '0'){
                setAmount(v.number*info.tenancy_pen_trx_price)
            }else{
                setAmount(v.number*info.tenancy_pen_number_trx_price)
            }
        }
    }

    return <>
        <Mask visible={listLoading}>
            <div style={{position: "absolute", top: "50%", color: '#00b578', left: "50%", display: "flex",}}>
                <SpinLoading color='#ffffff' />
            </div>
        </Mask>

        {listData.length < 1 ? <ErrorBlock status='empty' style={{height: "100%"}}  />:""}


        <List>
            {listData.map(item => (
                <List.Item
                    key={item.id}
                    description={ <Grid columns={3} gap={8}>
                        <Grid.Item>
                            类型：{item.payment === 0 ?"代扣":"笔数" }
                        </Grid.Item>
                        <Grid.Item>
                            消费金额：{item.amount} {GetCategoryName(item.category)}
                        </Grid.Item>
                        <Grid.Item>
                            用户：{item.telegram_id}
                        </Grid.Item>
                        <Grid.Item>
                            总笔数：{item.number}
                        </Grid.Item>
                        <Grid.Item>
                            使用笔数：{item.number_surplus}
                        </Grid.Item>
                        <Grid.Item>
                            状态：{GetTenancyStatusName(item.status)}
                        </Grid.Item>
                        <Grid.Item span={2}>
                            更新时间：{item.updated_at}
                        </Grid.Item>
                    </Grid>}
                >
                    {item.to_address}
                </List.Item>
            ))}
        </List>

        <FloatingBubble
            axis='xy'
            magnetic='x'
            style={{
                '--initial-position-bottom': '60px',
                '--initial-position-right': '24px',
                '--edge-distance': '24px',
            }}
            onClick={()=>{setAppstoreVisible(true)}}
        >
            <AppstoreOutline fontSize={32} />
        </FloatingBubble>

        <ActionSheet
            extra='请选择你要进行的操作'
            visible={appstoreVisible}
            actions={[
                { text: '添加订单', key: 'add' },
                { text: '关闭', key: 'close' },
            ]}
            onAction={action => {
                if (action.key === 'add') {
                    setVisibleAdd(true)
                }
                setAppstoreVisible(false)
            }}
            onClose={() => setAppstoreVisible(false)}
        />

        <Popup
            position='right'
            visible={visibleAdd}
            showCloseButton
            style={{width:"100%"}}
            bodyStyle={{width:"100%"}}
            onClose={() => {
                setVisibleAdd(false)
            }}
        >
            <div style={{padding: '40px 20px 20px'}}>
                <Form onFinish={addSubmit} mode='card' form={formRef}
                      footer={
                          <Button block type='submit' color='primary' size='middle'>
                              提交
                          </Button>
                      }>
                    <Form.Item label="用户TelegramId" name="telegram_id">
                        <Input/>
                    </Form.Item>

                    <Form.Item label="用户名称" name="user_name">
                        <Input />
                    </Form.Item>
                    <Form.Item label="收货方地址" name="to_address" rules={GetValidateAddress()}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="类型" name="payment">
                        <Radio.Group onChange={()=>{onChange()}} defaultValue='0'>
                            <Space direction='horizontal'>
                                <Radio value='1'>笔数</Radio>
                                <Radio value='0'>代扣</Radio>
                            </Space>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item label="笔数" name="number" rules={[{required: true, message: '不能为空'}]}>
                        <Stepper min={1} max={999} onChange={()=>{onChange()}} />
                    </Form.Item>
                    <Form.Item label="应付金额">
                        <Tag color="#f50">{amount.toFixed(2)}</Tag>
                    </Form.Item>
                </Form>
            </div>
        </Popup>
    </>

}