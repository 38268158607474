import {Button, Card, Col, DatePicker, Row, Statistic} from "antd";
import {DollarOutlined, NumberOutlined, SearchOutlined} from "@ant-design/icons";
import {useEffect, useState} from "react";
import {ajaxAdminStatisticsGetInfo} from "../../../../api";
import moment from "moment";
import dayjs from "dayjs";

export default function StatisticsIndex(props){
    const [info, setInfo] = useState({100:{},1:{},2:{},3:{},4:{},5:{},98:{},99:{}});
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");

    useEffect(() => {
        setStartTime(moment().format('YYYY-MM-DD'))
        setEndTime(moment().format('YYYY-MM-DD'))
        onSoso(moment().format('YYYY-MM-DD'),moment().format('YYYY-MM-DD'))
        return () => {
            // 清理操作，比如取消网络请求或者清除定时器等
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // 空数组意味着仅在组件挂载时执行这个effect

    const onChangeStart = (_, dateString) => {
        setStartTime(dateString)
    }

    const onChangeEnd = (_, dateString) => {
        setEndTime(dateString)
    }
    
    const onSoso = async (start_time,end_time) => {
        if (!start_time) {
            alert("开始时间不能为空")
            return false
        }
        if (!end_time) {
            alert("结束时间不能为空")
            return false
        }
        const result = await ajaxAdminStatisticsGetInfo({start_time: start_time, end_time: end_time})
        if (result.code === 1) {
            console.log("result",result.data)
            setInfo(result.data)
        }
    }

    return <>
        <Card title="统计">
            开始：<DatePicker defaultValue={dayjs(moment().format('YYYY-MM-DD'), 'YYYY-MM-DD')} onChange={onChangeStart}/> 结束：<DatePicker defaultValue={dayjs(moment().format('YYYY-MM-DD'), 'YYYY-MM-DD')} onChange={onChangeEnd}/>
            <Button onClick={() => {
                onSoso(startTime,endTime)
            }} style={{marginLeft: '10px'}} type="primary" icon={<SearchOutlined/>}>
                搜索
            </Button>
            <br/><br/><br/>

            <Row span={12} gutter={24}>
                <Col span={12}>
                    <Card title="总计">
                        <Row gutter={12}>
                            <Col span={12}>
                                <Statistic title="数量" value={info[100].number} suffix="次"
                                           prefix={<NumberOutlined/>}/>
                            </Col>
                            <Col span={12}>
                                <Statistic title="金额" value={info[100].amount} suffix="TRX"
                                           prefix={<DollarOutlined/>}/>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <br/> <br/>
            <Row span={12} gutter={24}>
                <Col span={12}>
                    <Card title="笔数">
                        <Row gutter={12}>
                            <Col span={12}>
                                <Statistic title="数量" value={info[4].number} suffix="次" prefix={<NumberOutlined/>}/>
                            </Col>
                            <Col span={12}>
                                <Statistic title="金额" value={info[4].amount} suffix="TRX" prefix={<DollarOutlined/>}/>
                            </Col>
                        </Row>
                    </Card>
                </Col>

                <Col span={12}>
                    <Card title="代理">
                        <Row gutter={12}>
                            <Col span={12}>
                                <Statistic title="数量" value={info[5].number} suffix="次" prefix={<NumberOutlined/>}/>
                            </Col>
                            <Col span={12}>
                                <Statistic title="金额" value={info[5].amount} suffix="TRX" prefix={<DollarOutlined/>}/>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <br/> <br/>
            <Row span={12} gutter={24}>
                <Col span={12}>
                    <Card title="小时">
                        <Row gutter={12}>
                            <Col span={12}>
                                <Statistic title="数量" value={info[3].number} suffix="次" prefix={<NumberOutlined/>}/>
                            </Col>
                            <Col span={12}>
                                <Statistic title="金额" value={info[3].amount} suffix="TRX" prefix={<DollarOutlined/>}/>
                            </Col>
                        </Row>
                    </Card>
                </Col>

                <Col span={12}>
                    <Card title="闪兑">
                        <Row gutter={12}>
                            <Col span={12}>
                                <Statistic title="数量" value={info[2].number} suffix="次" prefix={<NumberOutlined/>}/>
                            </Col>
                            <Col span={12}>
                                <Statistic title="金额" value={info[2].amount} suffix="TRX" prefix={<DollarOutlined/>}/>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>

            <br/> <br/>
            <Row span={12} gutter={24}>
                <Col span={12}>
                    <Card title="代理商充值">
                        <Row gutter={12}>
                            <Col span={12}>
                                <Statistic title="数量" value={info[99].number} suffix="次" prefix={<NumberOutlined/>}/>
                            </Col>
                            <Col span={12}>
                                <Statistic title="金额" value={info[99].amount} suffix="TRX" prefix={<DollarOutlined/>}/>
                            </Col>
                        </Row>
                    </Card>
                </Col>

                <Col span={12}>
                    <Card title="退款">
                        <Row gutter={12}>
                            <Col span={12}>
                                <Statistic title="数量" value={info[98].number} suffix="次" prefix={<NumberOutlined/>}/>
                            </Col>
                            <Col span={12}>
                                <Statistic title="金额" value={info[98].amount} suffix="TRX" prefix={<DollarOutlined/>}/>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </Card>
    </>
}