import React, {useEffect, useState} from "react";
import {ajaxAdminTronGetOrderList} from "../../../../../api";
import {
    GetBalanceStatusName,
    GetCategoryName,
    GetTronOrderStatusName, GetTronOrderTypesName
} from "../../../../../utils/define";
import {Button, Card, Descriptions, Modal, Space, Table, Tooltip} from "antd";
import {useParams} from "react-router-dom";


export default function TronOrderList(props){
    const param = useParams();

    const [info, setInfo] = useState({});
    const [listData, setListData] = useState([]);
    const [listPagination, setListPagination] = useState({showSizeChanger: false});
    const [listLoading, setListLoading] = useState(false);
    const [soSO,setSoSO] = useState({});
    const [visible, setVisible] = useState(false);


    useEffect(() => {
        initFetch()
        return () => {
            // 清理操作，比如取消网络请求或者清除定时器等
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [param.types]); // 空数组意味着仅在组件挂载时执行这个effect

    const initFetch = () => {
        fetch().then()
    }

    const fetch = async (params = {}) => {
        setListLoading(true)
        params.page = params.page ? params.page : 1
        params.types = param.types
        const result = await ajaxAdminTronGetOrderList(params)
        if (result.code === 1) {
            const listPaginationNew = { ...listPagination }
            listPaginationNew.total = result.data.total
            listPaginationNew.pageSize = result.data.limit
            listPaginationNew.showTotal = total => `总计 ${total} 条`
            listPaginationNew.current = params.page

            const listData = result.data.list

            setListData(listData)
            setSoSO(params);
            setListLoading(false)
            setListPagination(listPaginationNew)
        }
    }

    // const SoSubmit = (values) => {
    //     setSoSO(values);
    //     values.page = 1
    //     fetch(values).then()
    // }

    const handleTableChange = (pagination) =>{
        soSO.page = pagination.current
        fetch(soSO).then()
    }


    const columns = [
        {
            title: '类别',
            dataIndex: 'types',
            render: (text, item) => {
                return (
                    <> {GetTronOrderTypesName(item.types)} </>
                );
            },
        }, {
            title: '转出 / 转入',
            dataIndex: 'form',
            render: (text, item) => {
                return (
                    <Tooltip placement="topLeft" title={item.form +"->"+ item.to}> {item.form} -> {item.to}</Tooltip>
                );
            },
        },{
            title: '金额',
            dataIndex: 'category',
            render: (text, item) => {
                return (
                    <>{item.amount} {GetCategoryName(item.category)} </>
                );
            },
        }, {
            title: '交易时间',
            dataIndex: 'timestamp'
        } , {
            title: '状态',
            dataIndex: 'status',
            render: (text, item) => {
                return (
                    <> {GetTronOrderStatusName(item.status)} </>
                );
            },
        },   {
            title: '更新时间',
            dataIndex: 'updated_at'
        }, {
            title: "操作",
            render: (text, item) => {
                return (
                    <Space>
                        <Button type={'link'} onClick={() => {
                            setInfo(item)
                            setVisible(true)
                        }}>详情</Button>
                    </Space>
                );
            },
        }]
    return <>
        <Card title="列表" >
            <Table
                columns={columns}
                rowKey="id"
                pageSize={15}
                dataSource={listData}
                pagination={listPagination}
                loading={listLoading}
                onChange={handleTableChange}
            />
        </Card>
        <Modal open={visible} width={700} title={"详情"} forceRender={true} footer={null} onCancel={() => {setVisible(false)}}>
            <Descriptions column={1} bordered size="small">
                <Descriptions.Item label="类别">{GetTronOrderTypesName(info.types)}</Descriptions.Item>
                <Descriptions.Item label="转出">{info.form==="0" || info.form === ""?"无":info.form}</Descriptions.Item>
                <Descriptions.Item label="转入">{info.to}</Descriptions.Item>
                <Descriptions.Item label="金额类型">{GetCategoryName(info.category)}</Descriptions.Item>
                <Descriptions.Item label="金额">{info.amount}</Descriptions.Item>
                <Descriptions.Item label="交易时间">{info.timestamp}</Descriptions.Item>
                <Descriptions.Item label="交易txid">{info.txid}</Descriptions.Item>
                <Descriptions.Item label="状态">{GetTronOrderStatusName(info.status)}</Descriptions.Item>
                <Descriptions.Item label="处理说明">{info.process_content}</Descriptions.Item>
                <Descriptions.Item label="处理txid">{info.process_txid}</Descriptions.Item>
                <Descriptions.Item label="添加时间">{info.created_at}</Descriptions.Item>
                <Descriptions.Item label="更新时间">{info.updated_at}</Descriptions.Item>
            </Descriptions>
        </Modal>
    </>
}