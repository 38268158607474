import {Route, Routes} from "react-router-dom";
import PublicLogin from "../../adminh5/public/login";
import React from "react";
import H5AdminRouter from "./admin";

export default function Index(){
    return <>
        <Routes>
            <Route path='/login' element={<PublicLogin/>}/>
            <Route path='/*' element={<H5AdminRouter/>}/>
        </Routes>
    </>
}