import React, {useEffect, useState} from "react";
import {ErrorBlock, Grid, List, Mask, Result, SpinLoading, Button, NoticeBar} from "antd-mobile";
import {SmileOutline} from "antd-mobile-icons";
import {ajaxAdminOrganizationGetBalanceList, ajaxAdminOrganizationGetInfo} from "../../../api";
import {
    GetCategoryName,
    GetTronOrderTypesName
} from "../../../utils/define";


export default function Home(props){
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({info:{},balance_address:""});
    const [listData, setListData] = useState([]);

    useEffect(() => {
        initFetch()
        return () => {
            // 清理操作，比如取消网络请求或者清除定时器等
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // 空数组意味着仅在组件挂载时执行这个effect

    const initFetch = () => {
        fetch().then()
        fetchList().then()
    }
    const fetchList = async (params = {}) => {
        const result = await ajaxAdminOrganizationGetBalanceList(params)
        if (result.code === 1) {
            setListData(result.data.list)
        }
    }
    const fetch = async (params = {}) => {
        setLoading(true);
        let result = await ajaxAdminOrganizationGetInfo()
        if (result.code === 1) {
            setLoading(false);
            setData(result.data)
        }
    }

    return <>
        <Result
            icon={<SmileOutline />}
            status='success'
            title={"你好 "+data.info.name+", 欢迎使用系统!"}
            description={<div>余额：<Button type="like">{data.info.balance}</Button> 总充值：<Button type="like">{data.info.balance_total}</Button></div>}
        />
        <Mask visible={loading}>
            <div style={{position: "absolute", top: "50%", color: '#00b578', left: "50%", display: "flex",}}>
                <SpinLoading color='#ffffff' />
            </div>
        </Mask>

        {listData.length < 1 ? <ErrorBlock status='empty' style={{height: "100%"}}  />:""}
        <NoticeBar content='消息记录' color='default' />
            <List>
                {listData.map(item => (
                    <List.Item
                        key={item.id}
                        description={ <Grid columns={3} gap={8}>
                            <Grid.Item>
                                类别：{GetTronOrderTypesName(item.types)}
                            </Grid.Item>
                            <Grid.Item>
                                金额：{item.amount} {GetCategoryName(item.category)}
                            </Grid.Item>
                            <Grid.Item>
                                原始金额：{item.original_amount} {GetCategoryName(item.category)}
                            </Grid.Item>
                            <Grid.Item span={2}>
                                更新时间：{item.timestamp}
                            </Grid.Item>
                        </Grid>}
                    >
                        {item.to_address}
                    </List.Item>
                ))}
            </List>
    </>
}