import {Button, Card, Col, Form, Input, message, Row, Space, Spin} from "antd";
import React, {useEffect, useState} from "react";
import {
    ajaxAdminConfigGetAdminInfo, ajaxAdminConfigSetAdminEd
} from "../../../api";

const formRef = React.createRef()
export default function AdminConfig() {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        initFetch()
        return () => {
            // 清理操作，比如取消网络请求或者清除定时器等
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // 空数组意味着仅在组件挂载时执行这个effect

    const initFetch = () => {
        fetch().then()
    }
    const fetch = async (params = {}) => {
        setLoading(true);
        let result = await ajaxAdminConfigGetAdminInfo()
        if (result.code === 1) {
            setLoading(false);
            formRef.current.setFieldsValue(result.data)
            message.success('获取成功')
        }
    }

    const Submit = async (values) => {
        const res = await ajaxAdminConfigSetAdminEd(values)
        if (res.code === 1) {
            message.success('修改成功')
            initFetch()
        }
    }

    return <>
        <Card title="搜索">
            <Spin spinning={loading}>
                <Form labelCol={{span: 10}} wrapperCol={{span: 14}} onFinish={Submit}  ref={formRef}>
                    <Row gutter={[16, 24]}>
                        <Col span={12}>
                            <Form.Item label="05:00-15:00 1小时(65000)能量" name="energy11">
                                <Input  />
                            </Form.Item>
                            <Form.Item label="05:00-15:00 1小时(131000)能量" name="energy12">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="15:00-05:00 1小时(65000)能量" name="energy21">
                                <Input />
                            </Form.Item>
                            <Form.Item label="15:00-05:00 1小时(131000)能量" name="energy22">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="【代扣】1笔 能量TRX价格" name="tenancy_pen_trx_price">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item label="【笔数】1笔 能量TRX价格" name="tenancy_pen_number_trx_price">
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row justify="end">
                        <Col>
                            <Space>
                                <Button style={{width: 240}} type="primary" htmlType="submit">
                                    保存
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </Card>
    </>
}