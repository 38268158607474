import React, { Component } from "react";
import { Layout , Menu } from "antd";
import { Link } from 'react-router-dom';
import { getTypeIcon } from "../../../utils/icon";
import storageUtils from "../../../utils/storage";
import withRouter from "../../../utils/withRouter";

const { Sider } = Layout;

class LayoutLeft extends Component {
    state = {
        selectedKeys: [],
        openKeys: [],
        openUrl: '',
        collapsed: false,
        menuList: [],
        menuNodes: [],
        selectedKey: ''
    };

    getMenuNodes = (menuList) => {
        return menuList.map(item => {
            if (!item.children) {
                return {
                    key: item.id,
                    label: (
                        <Link to={{ pathname: item.url, state: { type: 1 } }}>
                            {getTypeIcon(item.icon)}
                            <span>{item.name}</span>
                        </Link>
                    )
                };
            } else {
                return {
                    key: item.id,
                    label: (
                        <span>
                            {getTypeIcon(item.icon)}
                            <span>{item.name}</span>
                        </span>
                    ),
                    children: this.getMenuNodes(item.children)
                };
            }
        });
    };

    onCollapse = collapsed => {
        this.props.setCollapsed(collapsed);
    };

    async componentDidMount() {
        const userInfo = storageUtils.getUser();
        console.log("userInfo",userInfo)
        if (!userInfo.menuList || userInfo.menuList.length < 1) {
            const list =  [];

            let name = ''
            if (userInfo.info.uid === 1){
                name = '（全部）'
            }

            if (userInfo.info.uid === 1){
                list.unshift({ id: '9900', name: '后台管理', parent: '0', url: '/admin', sort: '0', icon: 'home' ,children:[
                        {id: 9901, name: "站点管理", icon: "api", parent: 9900, url: "/admin/organization/list", sort: 99999},
                        {id: 9902, name: "网站配置", icon: "api", parent: 9900, url: "/admin/config/ed", sort: 99999},
                        {id: 9903, name: "消费记录(代理商)", icon: "api", parent: 9900, url: "/admin/balance/recharge/list", sort: 99999},
                        {id: 9904, name: "统计", icon: "api", parent: 9900, url: "/admin/statistics/index", sort: 99999},
                    ]
                });
            }else{
                list.push({ id: '1000', name: '机器人配置', parent: '0', url: '/admin', sort: '0', icon: 'home' ,children:[
                        {id: 1001, name: "机器人设置", icon: "api", parent: 1000, url: "/admin/telegram/ed", sort: 99999},
                        {id: 1002, name: "播报群组", icon: "api", parent: 1000, url: "/admin/telegram/group", sort: 99999},
                        {id: 1003, name: "余额充值", icon: "api", parent: 1000, url: "/admin/balance/recharge", sort: 99999},
                    ]
                });
            }

                // list.push({ id: '1100', name: '充值管理', parent: '0', url: '/admin', sort: '0', icon: 'home' ,children:[
                //         {id: 1101, name: "充值套餐", icon: "api", parent: 1100, url: "/admin/recharge/package/list", sort: 99999},
                //         {id: 1102, name: "充值列表", icon: "api", parent: 1100, url: "/admin/recharge/order/list", sort: 99999},
                //         {id: 1103, name: "充值记录", icon: "api", parent: 1100, url: "/admin/tron/order/list/1", sort: 99999},
                //     ]
                // });

                list.push({ id: '1200', name: '能量管理'+name, parent: '0', url: '/admin', sort: '0', icon: 'home' ,children:[
                       // {id: 1201, name: "笔数套餐", icon: "api", parent: 1200, url: "/admin/tenancy/package/list", sort: 99999},
                        {id: 1201, name: "笔数列表"+name, icon: "api", parent: 1200, url: "/admin/tenancy/order/list", sort: 99999},
                        {id: 1202, name: "小时交易"+name, icon: "api", parent: 1200, url: "/admin/tron/order/list/3", sort: 99999},
                        {id: 1203, name: "笔数交易"+name, icon: "api", parent: 1200, url: "/admin/tron/order/list/5", sort: 99999}
                    ]
                });

                list.push({ id: '1400', name: '闪兑管理'+name, parent: '0', url: '/admin', sort: '0', icon: 'home' ,children:[
                        {id: 1401, name: "闪兑交易"+name, icon: "api", parent: 1400, url: "/admin/tron/order/list/2", sort: 99999},
                    ]
                });
            if (userInfo.info.uid !== 1) {
                list.push({
                    id: '1300', name: '用户管理', parent: '0', url: '/admin', sort: '0', icon: 'home', children: [
                        {id: 1301, name: "用户列表", icon: "api", parent: 1300, url: "/admin/user/list", sort: 99999},
                        {id: 1302, name: "余额列表", icon: "api", parent: 1300, url: "/admin/balance/list", sort: 99999}
                    ]
                });
            }


            list.unshift({ id: '0', name: '首页', parent: '0', url: '/admin', sort: '0', icon: 'home' })
            userInfo.menuList = list;
            storageUtils.saveUser(userInfo);
        }

        this.initPah(this.props.router.uLocation.pathname, userInfo.menuList);
        const menu = storageUtils.getKey('admin-user-menu');
        if (!menu) {
            this.initPah(this.props.router.uLocation.pathname, userInfo.menuList);
        } else {
            this.setState({
                selectedKeys: menu.selectedKeys,
                openUrl: menu.openUrl,
                openKeys: menu.openKeys
            });
        }
        this.setState({
            menuList: userInfo.menuList,
            menuNodes: this.getMenuNodes(userInfo.menuList)
        });
    }

    initPah = (path, list) => {
        if (this.openUrl !== path) {
            for (let index = 0; index < list.length; index++) {
                if (list[index].children) {
                    for (let jjj = 0; jjj < list[index].children.length; jjj++) {
                        if (list[index].children[jjj].url === path) {
                            if (this.state.openKeys.length > 0) {
                                this.state.openKeys.concat([list[index].id]);
                                this.setState({
                                    selectedKeys: [list[index].children[jjj].id],
                                    openUrl: path
                                });
                            } else {
                                this.setState({
                                    selectedKeys: [list[index].children[jjj].id],
                                    openUrl: path,
                                    openKeys: [list[index].id]
                                });
                            }
                            return true;
                        }
                    }
                }
            }
        } else {
            return false;
        }
    }

    onOpenChange = openKeys => {
        this.setState({
            openKeys
        });
    };

    onClick = selectedKey => {
        this.setState({
            selectedKeys: [selectedKey.key]
        });
        storageUtils.setKey('admin-user-menu', {
            selectedKeys: [selectedKey.key],
            openUrl: this.state.openUrl,
            openKeys: this.state.openKeys,
        });
    };

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.selectedKey && nextProps.selectedKey !== this.state.selectedKey) {
            const selectedKey = nextProps.selectedKey.split("_");
            const userInfo = storageUtils.getUser();
            const list = userInfo.menuList;
            let open = 1;
            for (let index = 0; index < list.length; index++) {
                if (open === 0) {
                    break;
                }
                if (list[index].children) {
                    for (let jjj = 0; jjj < list[index].children.length; jjj++) {
                        if (list[index].children[jjj].id === selectedKey[0]) {
                            if (this.state.openKeys.indexOf(list[index].id) < 0) {
                                this.setState({
                                    openKeys: this.state.openKeys.concat(list[index].id)
                                });
                            }
                            open = 0;
                            break;
                        }
                    }
                }
            }
            this.setState({
                selectedKeys: [selectedKey[0]],
                selectedKey: nextProps.selectedKey,
            });
            storageUtils.setKey('menu', {
                selectedKeys: [selectedKey[0]],
                openUrl: this.state.openUrl,
                openKeys: this.state.openKeys,
            });
        }

        return true;
    }

    render() {
        return (
            <Sider
                style={{ minHeight: 'calc(100vh - 4.1rem)', background: 'white' }}
                width={256}
                collapsible
                collapsedWidth={80}
                trigger={null}
                collapsed={this.props.collapsed}
                onCollapse={this.onCollapse}
            >
                <Menu
                    onClick={this.onClick}
                    mode="inline"
                    theme="light"
                    onOpenChange={this.onOpenChange}
                    openKeys={this.state.openKeys}
                    selectedKeys={this.state.selectedKeys}
                    items={this.state.menuNodes} 
                    style={{ borderRight: 0 }}
                />
            </Sider>
        );
    }
}

export default withRouter(LayoutLeft);
