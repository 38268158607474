import {Selector} from "antd-mobile";
import React, {useEffect, useState} from "react";
import TenancyOrderList from "../order/list";
import TronOrderList from "../../tron/order/list";
export default function TenancyIndex(props){
    const [html, setHtml] = useState("");
    const [value, setValue] = useState('1')

    useEffect(() => {
        onChange("1")
        return () => {
            // 清理操作，比如取消网络请求或者清除定时器等
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // 空数组意味着仅在组件挂载时执行这个effect

    const onChange = (v) => {
        console.log("v.length",v)
        if (v !== undefined) {
            setValue(v[0])
            if (v === '1'){
                setHtml(<TenancyOrderList/>)
            } else if (v === '2'){
                setHtml(<TronOrderList types={5}/>)
            } else if (v === '3'){
                setHtml( <TronOrderList types={3}/>)
            }
        }
    }

    return <>
        <div style={{padding: '10px'}}>
            <Selector
                options={[
                    {
                        label: '笔数列表',
                        value: '1',
                    },
                    {
                        label: '笔数交易',
                        value: '2',
                    },
                    {
                        label: '小时交易',
                        value: '3',
                    },
                ]}
                value={[value]}
                defaultValue={['1']}
                onChange={(arr) => onChange(arr[0]) }
            />
        </div>

        {html}
    </>
}