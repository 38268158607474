import React, {useEffect, useState} from 'react'
import {ResultPage} from "antd-mobile";
import {Button, Form, Input} from "antd-mobile";
import {ajaxLoginGetCode, ajaxPublicLogin} from "../../../../api";
import {message} from "antd";
import storageUtils from "../../../../utils/storage";
import {useNavigate} from "react-router-dom";
import {SafetyCertificateOutlined} from "@ant-design/icons";

export default function Login(){
    const [imageBase64, setImageBase64] = useState("");
    const [code, setCode] = useState("");
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const onSubmit = async (values) => {
        console.log(values)
        const result = await ajaxPublicLogin({...values, captcha_id: code});
        if (result.code === 1) { // 登陆成功
            // 提示登陆成功
            const info = result.data.info || {}
            message.success('登陆成功')
            // 保存user
            const user = result.data
            storageUtils.saveToken(user.token, user.expire) // 保存到local中
            storageUtils.saveUser({
                'username': values.name,
                'menuList': [],
                'info': info,
                'config': {noticeStatus: 1,loginType:2}
            }); //存储用户资料
            navigate(`/adminh5/home`)
        } else {
            await getCode()
        }
    }

    useEffect(() => {
        getCode().then()
        return () => {
            // 清理操作，比如取消网络请求或者清除定时器等
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // 空数组意味着仅在组件挂载时执行这个effect

    const getCode = async () => {
        let res = await ajaxLoginGetCode()
        if (res.code === 1) {
            setImageBase64(res.data.img_data)
            setCode(res.data.captcha_id)
        }
    }

    //对密码进行自定义认证
    const validatePwd = async (rule, value = '') => {
        if (!value) {
            return Promise.reject('密码必须输入');
        } else if (value.length < 4) {
            return Promise.reject('密码长度不能小于4位');
        } else if (value.length > 32) {
            return Promise.reject('密码长度不能大于32位');
        } else if (!/^[a-zA-Z0-9_-]+$/.test(value)) {
            return Promise.reject('密码必须是英文、数字或下划线组成');
        }
        Promise.resolve()
    }

    return <>
        <ResultPage
            status='success'
            title='登录'
            description='Upaytrx系统登录'
        >
            <Form
                form={form}
                onFinish={onSubmit}
                footer={
                    <Button block type='submit' color='primary'>
                        马上登录
                    </Button>
                }
            >
                <Form.Item name='username' label='姓名' rules={[
                    {required: true, message: '账户必须输入'},
                    {min: 4, message: '账户至少4位'},
                    {max: 32, message: '账户至多32位'},
                    {pattern: /^[a-zA-Z0-9_-]+$/, message: '账户必须是英文,数字,下划线,减号'},
                ]}>
                    <Input placeholder='请输入姓名' />
                </Form.Item>
                <Form.Item name='password' label='密码' help="请输入有效密码" rules={[{required: true, message: '验证码必须输入',validator: validatePwd}]}>
                    <Input type="password" placeholder='请输入有效密码' />
                </Form.Item>
                <Form.Item name='captcha' label='验证码' rules={[{required: true, message: '验证码必须输入'}]}  extra={
                    <img alt={''} src={imageBase64} onClick={() => {
                        getCode().then()
                    }} style={{width: '120px', height: '50px'}}/>
                }>
                    <Input placeholder='验证码必须输入' prefix={<SafetyCertificateOutlined className="site-form-item-icon" style={{color: "rgba(0,0,0,.25)"}}/>} />
                </Form.Item>
            </Form>
        </ResultPage>
    </>
}