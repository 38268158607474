import React, {useEffect, useState} from "react";
import {Route, useLocation, useNavigate, Routes} from "react-router-dom";
import {NavBar, TabBar} from "antd-mobile";
import {
    AppOutline, HandPayCircleOutline,
    ReceivePaymentOutline,
    UnorderedListOutline,
} from 'antd-mobile-icons'
import './admin.css'
import Home from "../../adminh5/home";
import storageUtils from "../../../utils/storage";
import TenancyOrderList from "../../adminh5/tenancy/order/list";
import TelegramEd from "../../adminh5/telegram/ed";
import TronOrderList from "../../adminh5/tron/order/list";
import TenancyIndex from "../../adminh5/tenancy/index";

export default function Admin(){
    const [userInfo, setUserInfo] = useState({});

    useEffect(() => {
        const token = storageUtils.getToken();
        const user = storageUtils.getKey('admin-user');
        if (!token || token.length < 1 || !user) {
            window.location.href = "/adminh5/login";
        }
        setUserInfo(user.info)
        return () => {
            // 清理操作，比如取消网络请求或者清除定时器等
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // 空数组意味着仅在组件挂载时执行这个effect

    const Bottom = () => {
        const navigate = useNavigate();
        const location = useLocation()
        const { pathname } = location

        const setRouteActive = (value) => {
            navigate(value)
        }

        const tabs = [
            {
                key: '/adminh5/home',
                title: '首页',
                icon: <AppOutline />,
            },
            {
                key: '/adminh5/telegram/ed',
                title: '机器人设置',
                icon: <UnorderedListOutline />,
            },
            {
                key: '/adminh5/tenancy/index',
                title: '能量管理',
                icon: <HandPayCircleOutline />,
            },
            {
                key: '/adminh5/tron/order/list/3',
                title: '闪兑',
                icon: <ReceivePaymentOutline />,
            },
        ]

        return (
            <TabBar safeArea activeKey={pathname} onChange={value => setRouteActive(value)}>
                {tabs.map(item => (
                    <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
                ))}
            </TabBar>
        )
    }

    return <>
            <div className="app">
                <div className="top">
                    <NavBar back={null}>{userInfo.real_name} ({userInfo.username})</NavBar>
                </div>
                <div className="body">
                    <Routes initialEntries={['/adminh5/home']}>
                        <Route path="/home" element={<Home/>}/>
                        <Route path="/tenancy/index" element={<TenancyIndex/>}/>
                        <Route path="/tenancy/order/list" element={<TenancyOrderList/>}/>
                        <Route path="/telegram/ed" element={<TelegramEd/>}/>
                        <Route path="/tron/order/list/:types" element={<TronOrderList/>}/>
                    </Routes>
                </div>
                <div className="bottom">
                    <Bottom />
                </div>
            </div>
    </>
}